import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-grid-system'
import { translate, Translate } from 'react-i18nify'
import { withRouter } from 'react-router-dom'

import Alert from '../Alert/Alert'
import Button from '../Button/Button'
import GridCol from '../GridCol/GridCol'
import GridColOffset from '../GridCol/GridColOffset'

import Select from 'react-select'
import classNames from 'classnames'
import { connect } from 'react-redux'
class FormRegisterStep2 extends Component {
    constructor(props) {
        super(props)

        this.emailChange = this.emailChange.bind(this)
        this.passwordChange = this.passwordChange.bind(this)
        this.passwordAgainChange = this.passwordAgainChange.bind(this)
        this.toggleShowPassword = this.toggleShowPassword.bind(this)
        this.toggleShowPasswordAgain = this.toggleShowPasswordAgain.bind(this)
        this.submit = this.submit.bind(this)
        this.back = this.back.bind(this)

        const flexappOrgs = this.props.user.flexapp_organizations_public || []
        const organisations = flexappOrgs.slice(1, flexappOrgs.length)

        this.state = {
            email: '',
            password: '',
            passwordAgain: '',
            acceptedTerms: true,
            showCriteria: false,
            showPassword: false,
            showPasswordAgain: false,
            isLoading: false,
            organisation: null,
            organisationOptions: organisations.map((item) => ({
                value: item.id,
                label: item.name,
            })),
        }
    }

    componentDidMount() {
        if (this.props.state.email && this.props.state.email.length > 0) {
            this.setState({
                email: this.props.state.email,
            })
        }

        if (this.props.state.password && this.props.state.password.length > 0) {
            this.setState({
                password: this.props.state.password,
            })
        }

        if (this.props.state.passwordAgain && this.props.state.passwordAgain.length > 0) {
            this.setState({
                passwordAgain: this.props.state.passwordAgain,
            })
        }
    }

    isPlanner() {
        const { pathname } = this.props.location

        return pathname === '/registreren/planner'
    }

    emailChange(event) {
        this.setState({
            email: event.target.value,
        })
    }

    passwordChange(event) {
        this.setState({
            password: event.target.value,
        })
    }

    passwordAgainChange(event) {
        this.setState({
            passwordAgain: event.target.value,
        })
    }

    toggleShowPassword() {
        this.setState({ showPassword: !this.state.showPassword })
    }

    toggleShowPasswordAgain() {
        this.setState({ showPasswordAgain: !this.state.showPasswordAgain })
    }

    submit(e) {
        e.preventDefault()
        this.setState({ isLoading: true })

        const plannerFields = {
            organisation_id: this.state.organisation?.value,
            isPlanner: this.isPlanner(),
        }

        this.props.onSubmit(
            this.state.email,
            this.state.password,
            this.state.passwordAgain,
            this.state.acceptedTerms,
            plannerFields,
        )
    }

    onReturn() {
        this.setState({ isLoading: false })
    }

    back() {
        this.props.onBack(this.state.email, this.state.password, this.state.passwordAgain)
    }

    getClassNames(key, value) {
        let c = key !== 'organisation' ? 'form__input--text' : ''

        if (this.props.errorFields.indexOf(key) > -1) {
            c += ' error'
        }

        if (value && value.length > 0) {
            c += ' has-value'
        }

        if (key === 'email') {
            console.log(
                '[ Register ]',
                'Get Step2 Classname',
                key,
                this.props.errorFields,
                this.props.errorFields.indexOf(key) > -1,
                c,
            )
        }
        return c
    }

    checkPasswordCriteria(type) {
        switch (type) {
            case 'minimum':
                return this.state.password.match(/(^.{8,}$)/)
            case 'small':
                return this.state.password.match(/([a-z])/)
            case 'capital':
                return this.state.password.match(/([A-Z])/)
            case 'number':
                return this.state.password.match(/([0-9])/)
            case 'special':
                return this.state.password.match(/([^\w\s]|_)/)
            case 'match':
                return (
                    this.state.password === this.state.passwordAgain &&
                    this.state.passwordAgain.length > 0 &&
                    this.state.password.length > 0
                )
        }
    }

    render() {
        const { className, onSubmit, errorMessages, errorFields, onBack, state } = this.props

        return (
            <form className={this.props.className} onSubmit={this.submit}>
                <Row>
                    <GridColOffset />
                    <GridCol>
                        <h1>
                            <Translate value="register.create_account" />
                        </h1>

                        {errorMessages && errorMessages.length > 0 && (
                            <Alert type="alert--error">
                                <ul>
                                    {errorMessages.map(function (value) {
                                        return <li key={value}>{value}</li>
                                    })}
                                </ul>
                            </Alert>
                        )}

                        <fieldset className="form__fieldset">
                            <div className="form__item">
                                <i className="form__icon icons icon-envelope" />
                                <input
                                    onChange={this.emailChange}
                                    className={this.getClassNames('email', this.state.email)}
                                    type="email"
                                    id="email"
                                    value={this.state.email}
                                    autoFocus
                                    tabIndex="1"
                                />
                                <label className="form__label" htmlFor="email">
                                    <Translate value="labels.email" />
                                </label>
                            </div>
                            <div className="form__item">
                                <i className="form__icon icons icon-lock" />
                                <span
                                    className={`mdi form__password_icon ${
                                        this.state.showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                    }`}
                                    onClick={this.toggleShowPassword}
                                />
                                <input
                                    onFocus={() => this.setState({ showCriteria: true })}
                                    onChange={this.passwordChange}
                                    className={this.getClassNames('password', this.state.password)}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    id="password"
                                    value={this.state.password}
                                    tabIndex="2"
                                />
                                <label className="form__label" htmlFor="password">
                                    <Translate value="labels.password" />
                                </label>
                            </div>
                            <div className="form__item">
                                <span
                                    className={`mdi form__password_icon ${
                                        this.state.showPasswordAgain ? 'mdi-eye' : 'mdi-eye-off'
                                    }`}
                                    onClick={this.toggleShowPasswordAgain}
                                />
                                <input
                                    onChange={this.passwordAgainChange}
                                    className={this.getClassNames(
                                        'repeat_password',
                                        this.state.passwordAgain,
                                    )}
                                    type={this.state.showPasswordAgain ? 'text' : 'password'}
                                    id="repeat_password"
                                    value={this.state.passwordAgain}
                                    tabIndex="3"
                                />
                                <label className="form__label" htmlFor="repeat_password">
                                    <Translate value="labels.repeat_password" />
                                </label>
                            </div>
                            {this.isPlanner() && (
                                <div className={'form__item'}>
                                    <Select
                                        className={classNames(
                                            'select--alt',
                                            this.getClassNames('organisation'),
                                        )}
                                        classNamePrefix="select"
                                        id="current_employer"
                                        onChange={(organisation) => {
                                            this.setState({ organisation })
                                        }}
                                        value={this.state.organisation}
                                        clearable={false}
                                        options={this.state.organisationOptions}
                                        placeholder={translate(
                                            'onboarding.alt1.step5.select_current_employer_placeholder',
                                        )}
                                        isSearchable={false}
                                    />
                                    <label className="form__label" htmlFor="current_employer">
                                        <Translate value="onboarding.alt1.step5.select_current_employer" />
                                    </label>
                                </div>
                            )}

                            {this.state.showCriteria && (
                                <Alert type={'alert--criteria'}>
                                    <p>{translate('register.contain')}</p>

                                    <ul>
                                        <li>
                                            <span
                                                className={
                                                    'icon_check ' +
                                                    (this.checkPasswordCriteria('minimum')
                                                        ? 'is-active'
                                                        : '')
                                                }
                                            />
                                            <span>
                                                {translate('register.password_criteria_minimum')}
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                className={
                                                    'icon_check ' +
                                                    (this.checkPasswordCriteria('small')
                                                        ? 'is-active'
                                                        : '')
                                                }
                                            />
                                            <span>
                                                {translate('register.password_criteria_small')}
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                className={
                                                    'icon_check ' +
                                                    (this.checkPasswordCriteria('capital')
                                                        ? 'is-active'
                                                        : '')
                                                }
                                            />
                                            <span>
                                                {translate('register.password_criteria_capital')}
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                className={
                                                    'icon_check ' +
                                                    (this.checkPasswordCriteria('number')
                                                        ? 'is-active'
                                                        : '')
                                                }
                                            />
                                            <span>
                                                {translate('register.password_criteria_number')}
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                className={
                                                    'icon_check ' +
                                                    (this.checkPasswordCriteria('special')
                                                        ? 'is-active'
                                                        : '')
                                                }
                                            />
                                            <span>
                                                {translate(
                                                    'register.password_criteria_special_character',
                                                )}
                                            </span>
                                        </li>
                                    </ul>
                                    <p>
                                        <br />
                                        <Translate value="register.password_criteria_match" />
                                    </p>
                                </Alert>
                            )}

                            <div className="form__item--checkbox">
                                <div className="form__checkbox form__gc">
                                    <label className="form__checkbox__label">
                                        <Translate value="register.i_accept" />
                                        <br />
                                        <a
                                            target="_blank"
                                            href={this.props.config.whitelabel.terms_and_conditions}
                                            rel="noreferrer"
                                        >
                                            <Translate value="register.tou" />
                                        </a>
                                    </label>
                                </div>
                            </div>
                        </fieldset>

                        <div className="form__button_container">
                            <button
                                type="submit"
                                className={
                                    this.props.isLoading ? 'button--cta is-loading' : 'button--cta'
                                }
                                onClick={this.submit}
                                tabIndex="5"
                            >
                                {translate('buttons.create_account')}
                            </button>

                            <Button
                                className="button--cta"
                                buttonText={translate('buttons.back')}
                                onClick={this.back}
                                tabIndex="6"
                            />
                        </div>
                    </GridCol>
                </Row>
            </form>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

export default withRouter(connect(mapStateToProps)(FormRegisterStep2))

FormRegisterStep2.propTypes = {
    className: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    errorMessages: PropTypes.array,
    errorFields: PropTypes.array,
    onBack: PropTypes.func.isRequired,
    state: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
}
