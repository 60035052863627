import React, { Component } from 'react'
import { translate, Translate } from 'react-i18nify'

import { Hidden } from 'react-grid-system'
import Button from '../../Button/Button'
import { diffProfileItems, getJobs } from '../../../store/actions/user'
import { checkForEmptyFields, isRequired } from '../../../helpers'
import AlertBox from '../../Alert/AlertBox'
import FormSchoolSubjects from '../../Forms/FormSchoolSubjects'
import MultiSelect from '../../MultiSelect/MultiSelect'

function differenceSetOperation(a, b) {
    const bSet = new Set(b)
    return a.filter((n) => !bSet.has(n))
}

export default class TypesOfJobs extends Component {
    constructor(props) {
        super(props)

        if (props.state) {
            this.state = props.cache['typesOfJob']
        } else {
            this.state = {
                loading: false,
                error: null,
                fields: [],
                requiredFields: ['job'],

                jobs: [],
                job: props.user.profile.job_interests.map((i) => ({ value: i.id, label: i.name })),
            }
        }
        this.formRef = React.createRef()
    }

    componentDidMount() {
        getJobs(this.props.auth, false, (response) => {
            this.setState({
                jobs: response.map((job) => ({ value: job.id, label: job.name })),
            })
        })
    }

    save() {
        if (this.state.loading) {
            return
        }
        console.log('save')

        let empty = checkForEmptyFields(this.state)
        if (empty.length > 0) {
            this.setState({ error: [translate('errors.empty_field')], fields: empty })
            return
        }
        this.setState({ loading: true })

        const oldInterests = this.props.user.profile.job_interests.map((interest) => interest.id)
        const toAdd = differenceSetOperation(
            this.state.job.map((i) => i.value),
            oldInterests,
        )
        const toRemove = differenceSetOperation(
            oldInterests,
            this.state.job.map((i) => i.value),
        )

        this.props.dispatch(
            diffProfileItems(
                'job_interests',
                toAdd.map((id) => ({ id })),
                toRemove,
                this.props.auth,
                () => {
                    this.setState({
                        loading: false,
                        error: false,
                        fields: [],
                    })
                    if (this.formRef.current) {
                        this.formRef.current.save()
                    } else {
                        this.props.saveCache('typesOfJob', this.state)
                        this.props.nextStep()
                    }
                },
                () => {
                    console.log('[ Update of job interest failed ]')

                    this.setState({
                        loading: false,
                        error: [translate('errors.account_item_already_present')],
                        fields: ['study_level'],
                    })
                },
            ),
        )
    }

    getClassName(key) {
        let c = 'select--alt-black has-value'

        if (this.state.fields.includes(key)) {
            c += ' error'
        }

        return c
    }
    addErrors(err) {
        this.setState({ errors: [err] })
    }

    addErrorFields(fields) {
        this.setState({ fields: fields })
    }

    stopLoading() {
        this.setState({ loading: false })
    }

    render() {
        return (
            <div className="card--simple">
                <div className="onboarding__content">
                    <h1>{translate('onboarding.titleStep4')}</h1>
                    <p>{translate('onboarding.textStep4')}</p>
                    <div className={'form'}>
                        <div className={'form__item has-value' + isRequired('job', this.state)}>
                            <MultiSelect
                                className={this.getClassName('job')}
                                classNamePrefix="select"
                                name="job"
                                id="job"
                                onChange={(selected) => {
                                    this.setState({ job: selected })
                                }}
                                value={this.state.job}
                                clearable={false}
                                options={this.state.jobs}
                                isSearchable={true}
                                placeholder={translate('onboarding.jobTitlePlaceholder')}
                            />
                            <label className="form__label" htmlFor="job">
                                <Translate value="onboarding.jobTitle" />
                            </label>
                        </div>
                    </div>
                </div>

                {this.props.config.whitelabel.show_school_subjects &&
                    this.state.job.some((j) => j.label === 'Leraar' || j.label === 'Docent') && (
                        <div>
                            <FormSchoolSubjects
                                ref={this.formRef}
                                showActions={false}
                                darkMode={false}
                                onSave={() => {
                                    this.props.saveCache('typesOfJob', this.state)
                                    this.props.nextStep(this.state)
                                }}
                                cancel={() => {
                                    this.props.saveCache('typesOfJob', this.state)
                                    this.props.prevStep()
                                }}
                                addErrors={this.addErrors}
                                stopLoading={this.stopLoading}
                            />
                        </div>
                    )}

                {this.state.error && <AlertBox messages={this.state.error} type={'error'} />}

                <div className="card--simple__buttons">
                    <div>
                        <Button
                            onClick={() => this.save()}
                            className={
                                'button--cta inline-block' +
                                (this.state.loading ? ' is-loading' : '')
                            }
                            buttonText={translate('buttons.continue')}
                        />
                    </div>

                    <Hidden xs>
                        <button
                            onClick={() => this.props.prevStep(this.state)}
                            className="button--link inline-block"
                        >
                            <i className="mdi mdi-arrow-left" />
                            <Translate value="onboarding.button_back" />
                        </button>
                    </Hidden>
                </div>
            </div>
        )
    }
}
