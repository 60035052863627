import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row } from 'react-grid-system'
import { Translate } from 'react-i18nify'
import { PATH_ABOUT } from '../../constants/Constants'

import GridCol from '../GridCol/GridCol'
import GridColOffset from '../GridCol/GridColOffset'
import { connect } from 'react-redux'

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <Container>
                    <Row>
                        <GridCol>
                            <Link to={PATH_ABOUT}>
                                <Translate value="footer.about" />
                            </Link>
                            <a
                                target="_blank"
                                href={
                                    this.props.config.whitelabel &&
                                    this.props.config.whitelabel.privacy_and_conditions
                                }
                                rel="noreferrer"
                            >
                                <Translate value="footer.privacy_and_terms" />
                            </a>
                            {this.props.config.whitelabel &&
                                this.props.config.whitelabel.show_roadmap && (
                                    <Link to="roadmap">
                                        <Translate value="footer.roadmap" />
                                    </Link>
                                )}
                        </GridCol>
                    </Row>
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { config } = state

    return {
        config,
    }
}

export default connect(mapStateToProps)(Footer)
