import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { Translate } from 'react-i18nify'
import Panel from '../Panel/Panel'

class TalentpoolDeclinedPanel extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <Panel className="panel">
                            <h3>
                                <Translate value="menu.talentpool" />
                            </h3>
                            <p>
                                <Translate value="talentpool.declined" />
                            </p>
                        </Panel>
                    </Col>
                </Row>
            </Container>
        )
    }
}

// Wrap the component to inject dispatch and state into it
export default TalentpoolDeclinedPanel
