import React, { Component } from 'react'
import { translate, Translate } from 'react-i18nify'

import Button from '../../Button/Button'
import { Hidden, Col, Row } from 'react-grid-system'
import { updateProfileItem } from '../../../store/actions/user'
import { checkForEmptyFields, isRequired, processNrForInput } from '../../../helpers'
import AlertBox from '../../Alert/AlertBox'
import { getCleanCompetences } from '../../../store/actions/user'
import MultiSelect from '../../MultiSelect/MultiSelect'
import { diffProfileItems, editProfileItem } from '../../../store/actions/user'

function differenceSetOperation(a, b) {
    const bSet = new Set(b)
    return a.filter((n) => !bSet.has(n))
}

export default class Competencies extends Component {
    constructor(props) {
        super(props)

        if (props.cache['competencies']) {
            this.state = props.cache['competencies']
        } else {
            this.state = {
                loading: false,
                error: false,
                errors: [],
                fields: [],
                requiredFields: ['hours_from', 'hours_till', 'chosedCompetences'],
                competences: [],
                city: null,
                chosedCompetences: props.user.profile.competences.map((i) => ({
                    value: i.id,
                    label: i.name,
                })),
                hours_from: props.user.profile.field_values.find((i) => i.code === 'hours_from')
                    ?.value,
                hours_till: props.user.profile.field_values.find((i) => i.code === 'hours_till')
                    ?.value,
                open_for_projects: props.user.profile.wants_project_vacancies,
            }
        }
    }

    componentDidMount() {
        getCleanCompetences((c) =>
            this.setState({ competences: c.map((i) => ({ label: i.name, value: i.id })) }),
        )
    }

    saveHours() {
        return new Promise((res, rej) => {
            let fields = ['hours_from', 'hours_till']
            let fields_update = []
            for (let field of fields) {
                fields_update.push({
                    code: field,
                    value: this.state[field],
                })
            }
            this.props.dispatch(
                editProfileItem(
                    'fields',
                    {
                        account_fields: fields_update,
                    },
                    this.props.auth,
                    () => {
                        res()
                    },
                    (status, error) => {
                        rej(error)
                    },
                ),
            )
        })
    }

    addErrors(e) {
        this.setState(({ errors }) => ({
            errors: [...errors, ...e],
        }))
    }

    addErrorFields(f) {
        this.setState(({ fields }) => ({
            fields: [...fields, ...f],
        }))
    }

    save() {
        this.setState({
            errors: [],
            fields: [],
        })

        if (this.state.loading) {
            return
        }
        let empty = checkForEmptyFields(this.state)
        if (
            +this.state.hours_from > +this.state.hours_till ||
            +this.state.hours_till > 60 ||
            +this.state.hours_from < 1
        ) {
            this.addErrors([translate('errors.hours_per_week_not_valid')])
            this.addErrorFields(['hours_from', 'hours_till'])
            return
        }
        if (empty.length > 0) {
            this.setState({ error: true, fields: empty })
            return
        }
        this.setState({ loading: true })

        const oldComp = this.props.user.profile.competences.map((c) => c.id)
        const nowComp = this.state.chosedCompetences.map((i) => i.value)
        const toAdd = differenceSetOperation(nowComp, oldComp)
        const toRemove = differenceSetOperation(oldComp, nowComp)

        this.props.dispatch(
            diffProfileItems(
                'competences',
                toAdd.map((id) => ({ id })),
                toRemove.map((id) => id),
                this.props.auth,
                () => {
                    this.saveHours().then(() => {
                        this.props.dispatch(
                            updateProfileItem(
                                'wants_project_vacancies',
                                { wants_project_vacancies: this.state.open_for_projects },
                                this.props.auth,
                                () => this.onSuccess(),
                                () => {},
                            ),
                        )
                    })
                },
                () => {},
            ),
        )
    }

    onSuccess() {
        this.setState(
            {
                error: false,
                errors: [],
                fields: [],
                loading: false,
            },
            () => {
                this.props.saveCache('competencies', this.state)
                this.props.nextStep()
            },
        )
    }

    getClassNames(item, field) {
        let c = ''

        if (item !== null && item !== '' && ('' + item).length > 0) {
            c += ' has-value'
        }
        if (this.state.fields.indexOf(field) !== -1) {
            c += ' error'
        }
        return c
    }

    render() {
        const { hours_from, hours_till } = this.state
        return (
            <div className="card--simple">
                <div className="onboarding__content">
                    <h1>{translate('onboarding.titleStepVindwerk')}</h1>
                    <p>{translate('onboarding.textStepVindwerk')}</p>
                    <div className={'form'}>
                        <div className={'form__item' + isRequired('chosedCompetences', this.state)}>
                            <MultiSelect
                                value={this.state.chosedCompetences || []}
                                id="competencess"
                                classNames={this.getClassNames(
                                    this.state.chosedCompetences,
                                    'chosedCompetences',
                                )}
                                onChange={(value) => {
                                    this.setState({ chosedCompetences: value })
                                }}
                                options={this.state.competences}
                                placeholder={translate('onboarding.stepVindwerk.competence')}
                                label={translate('onboarding.stepVindwerk.competences')}
                            ></MultiSelect>
                        </div>
                        <div>
                            <div className="form__item form__item__label">
                                <Translate value="profile.hours_per_week" />
                            </div>
                            <Row>
                                <Col xs={6}>
                                    <div
                                        className={
                                            'form__item' + isRequired('hours_from', this.state)
                                        }
                                    >
                                        <input
                                            type="text"
                                            pattern="[0-9]*"
                                            id="hours_from"
                                            className={
                                                'form__input--text' +
                                                this.getClassNames(hours_from, 'hours_from')
                                            }
                                            name="hours_from"
                                            value={hours_from}
                                            onChange={(e) => {
                                                this.setState({
                                                    hours_from: processNrForInput(e.target.value),
                                                })
                                            }}
                                        />
                                        <label className="form__label" htmlFor="hours_from">
                                            <Translate value="profile.hours_from" />
                                        </label>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div
                                        className={
                                            'form__item' + isRequired('hours_till', this.state)
                                        }
                                    >
                                        <input
                                            type="text"
                                            pattern="[0-9]*"
                                            id="hours_till"
                                            className={
                                                'form__input--text' +
                                                this.getClassNames(hours_till, 'hours_till')
                                            }
                                            name="hours_till"
                                            value={hours_till}
                                            onChange={(e) => {
                                                this.setState({
                                                    hours_till: processNrForInput(e.target.value),
                                                })
                                            }}
                                        />
                                        <label className={'form__label'} htmlFor="hours_till">
                                            <Translate value="profile.hours_till" />
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row className="margin-top">
                            <Col xs={12}>
                                <div className="form__item form__item--checkbox">
                                    <div className="form__checkbox form__checkbox">
                                        <input
                                            type="checkbox"
                                            name="cb_styled"
                                            id="checkbox_styled"
                                            checked={this.state.open_for_projects}
                                            onChange={(e) => {
                                                this.setState({
                                                    open_for_projects: e.target.checked,
                                                })
                                            }}
                                            className="form__checkbox__input"
                                        />
                                        <label
                                            className="form__checkbox__label"
                                            htmlFor="checkbox_styled"
                                        >
                                            <Translate value="onboarding.stepVindwerk.open_for_projects" />
                                        </label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className={'form__item' + isRequired('city', this.state)}></div>
                    </div>
                </div>

                {this.state.error && (
                    <AlertBox messages={[translate('errors.empty_field')]} type={'error'} />
                )}
                {!!this.state.errors.length && (
                    <AlertBox messages={[this.state.errors]} type={'error'} />
                )}

                <div className="card--simple__buttons">
                    <div>
                        <Button
                            onClick={() => this.save()}
                            className={
                                'button--cta inline-block' +
                                (this.state.loading ? ' is-loading' : '')
                            }
                            buttonText={translate('buttons.continue')}
                        />
                    </div>

                    <Hidden xs>
                        <button
                            onClick={() => {
                                if (this.state.loading) {
                                    return
                                }
                                this.props.saveCache('competencies', this.state)
                                this.props.prevStep()
                            }}
                            className="inline-block button--link"
                        >
                            <i className="mdi mdi-arrow-left" />
                            <Translate value="onboarding.button_back" />
                        </button>
                    </Hidden>
                </div>
            </div>
        )
    }
}
