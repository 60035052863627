import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row } from 'react-grid-system'
import { translate, Translate } from 'react-i18nify'
import { register } from '../store/actions/auth'
import { PATH_LOGIN } from '../constants/Constants'
import { getErrorFields, getErrorMessages, isSignedIn } from '../helpers'
import { fetchFlexappPublicOrganizations } from '../store/actions/user'
import FormRegisterStep1 from '../components/Forms/FormRegisterStep1'
import FormRegisterStep2 from '../components/Forms/FormRegisterStep2'
import GridCol from '../components/GridCol/GridCol'
import GridColOffset from '../components/GridCol/GridColOffset'
import ModalWindow from '../components/Modal/Modal'
import history from '../history'

import { checkSublabel, restoreConfig } from '../store/actions/config'

var map

class Register extends Component {
    constructor(props) {
        super(props)

        map = {
            email: translate('labels.email'),
            firstname: translate('labels.first_name'),
            lastname: translate('labels.last_name'),
            password: translate('labels.password'),
            repeat_password: translate('labels.password_again'),
            general_terms: translate('register.tou'),
        }

        this._isMounted = false

        this.state = {
            plannerFields: {},
            isLoading: false,
            success: false,
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            passwordAgain: '',
            acceptedTerms: false,
            currentStep: 1,
            formError: '',
            errorArray: [],
            errorFields: [],
        }
        this.submitStep1 = this.submitStep1.bind(this)
        this.submitStep2 = this.submitStep2.bind(this)
        this.onBack = this.onBack.bind(this)
    }

    componentWillMount() {
        if (isSignedIn(this.props.state.auth)) {
            const redirect = this.props.state.config.whitelabel.home_page
            if (redirect && redirect[0] !== '/') {
                return history.push('/' + redirect)
            }
            history.push(redirect)
        }
        this.props.dispatch(checkSublabel(this.props.state.config.whitelabel, []))
        if (this.props.config.whitelabel.flexapp.used) {
            this.props.dispatch(
                fetchFlexappPublicOrganizations(this.props.auth, () => {}, this.onFetchError),
            )
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname === '/registreren/student' && this.props.state.config) {
            this.props.dispatch(
                checkSublabel(this.props.state.config.whitelabel, [{ code: 'student' }]),
            )
        } else {
            this.props.dispatch(checkSublabel(this.props.state.config.whitelabel, []))
        }
    }

    componentWillUnmount() {
        if (!isSignedIn(this.props.state.auth)) {
            this.props.dispatch(restoreConfig())
        }
    }

    submitStep1(firstname, lastname, accountTypeId) {
        //Reset the form error to remove any error messages on submit.
        this.setState(
            {
                formError: '',
                errorFields: [],
            },
            function () {
                if (!firstname || firstname.length < 1) {
                    //Fields are not correct
                    this.addErrorField('first_name')
                }

                if (!lastname || lastname.length < 1) {
                    //Fields are not correct
                    this.addErrorField('last_name')
                }

                if (!firstname || firstname.length < 1 || !lastname || lastname.length < 1) {
                    //Fields are not correct

                    this.setState({
                        formError: translate('errors.empty_field'),
                    })

                    return
                }

                this.setState({
                    firstname: firstname,
                    lastname: lastname,
                    account_type_id: accountTypeId,
                    currentStep: 2,
                })
            },
        )
    }

    submitStep2(mail, password, passwordAgain, acceptedTerms, plannerFields) {
        this.setState(
            {
                errorArray: [],
                errorFields2: [],
            },
            function () {
                if (plannerFields.isPlanner && !plannerFields.organisation_id) {
                    this.setState({
                        isLoading: false,
                    })
                    this.addError(translate('errors.organisation_is_required'))
                    return
                }
                if (acceptedTerms) {
                    this.setState(
                        {
                            email: mail,
                            password: password,
                            passwordAgain: passwordAgain,
                            acceptedTerms: acceptedTerms,
                            plannerFields,
                        },
                        function () {
                            this.finish()
                        },
                    )
                } else {
                    this.setState({
                        isLoading: false,
                    })
                    this.addError(translate('errors.terms_not_accepted'))
                }
            },
        )
    }

    onSuccess() {
        this.setState({
            success: true,
            isLoading: false,
        })
    }

    onError(status, errorObject) {
        switch (status) {
            case 400:
            case 404:
            case 422:
                this.setError('')
                this.setState(
                    {
                        errorArray: [],
                        isFetching: false,
                        isLoading: false,
                    },
                    function () {
                        if (errorObject.hasOwnProperty('errors')) {
                            let messages = getErrorMessages(errorObject.errors, map)
                            let fields = getErrorFields(errorObject.errors)

                            console.log('[ Register ]', 'Error fields', fields)
                            this.addErrors(messages)
                            this.addErrorFields(fields)
                        }
                    },
                )
                break
            default:
                this.setState({
                    isLoading: false,
                })

                this.addErrors([translate('errors.general')])
                break
        }
    }

    addErrors(errors) {
        this.setState({
            errorArray: errors,
        })
    }

    addErrorFields(fields) {
        this.setState({
            errorFields: fields,
        })
    }

    addError(error) {
        var errors = this.state.errorArray
        errors.push(error)

        this.setState({
            errorArray: errors,
        })
    }

    addErrorField(field) {
        let fields = this.state.errorFields
        fields.push(field)

        this.setState({
            errorFields: fields,
        })
    }

    finish() {
        let tgId = this.props.match.params.target_group_id
        let body = {
            ...this.state.plannerFields,
            email: this.state.email,
            first_name: this.state.firstname,
            last_name: this.state.lastname,
            password: this.state.password,
            password_confirmation: this.state.passwordAgain,
            account_type_id: this.state.account_type_id,
            target_group_id: typeof tgId === 'undefined' ? undefined : parseInt(tgId),
            general_terms: this.state.acceptedTerms ? 1 : 0,
        }

        this.setState(
            {
                isLoading: true,
            },
            () => {
                register(
                    body,
                    () => this.onSuccess(),
                    (status, error) => this.onError(status, error),
                    this.props.state.auth,
                )
            },
        )
    }

    closeRegisterModal() {
        history.push('/' + PATH_LOGIN)
    }

    setError(msg) {
        this.setState({
            formError: msg,
        })
    }

    onBack(email, password, passwordAgain) {
        this.setState({
            email: email,
            password: password,
            passwordAgain: passwordAgain,
            currentStep: 1,
            errorArray: [],
            errorFields2: [],
        })
    }

    render() {
        // injected by connect call
        const { dispatch, data } = this.props

        const formClass = `form text-center`

        return (
            <div className="react-native-web">
                <Container>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            {this.state.currentStep == 1 && (
                                <FormRegisterStep1
                                    className={formClass}
                                    onSubmit={this.submitStep1}
                                    errorMessage={this.state.formError}
                                    errorFields={this.state.errorFields}
                                    state={this.state}
                                    account_type_code={this.props.match.params.account_type_code}
                                />
                            )}

                            {this.state.currentStep == 2 && (
                                <FormRegisterStep2
                                    isLoading={this.state.isLoading}
                                    className={formClass}
                                    onSubmit={this.submitStep2}
                                    onBack={this.onBack}
                                    errorMessages={this.state.errorArray}
                                    errorFields={this.state.errorFields}
                                    state={this.state}
                                    config={this.props.state.config}
                                />
                            )}
                        </GridCol>
                    </Row>
                </Container>

                {this.state.success && (
                    <ModalWindow
                        title={translate('headers.email_sent')}
                        show
                        onClose={this.closeRegisterModal}
                        buttonText={translate('buttons.to_login')}
                    >
                        <p>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: translate('texts.email_sent', {
                                        email: this.state.email,
                                    }),
                                }}
                            />
                        </p>
                    </ModalWindow>
                )}
            </div>
        )
    }
}

Register.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

//const select = state => state;

function select(state) {
    return {
        state: state,
        config: state.config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(select)(Register)
