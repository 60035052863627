import React from 'react'
import { Translate } from 'react-i18nify'
import { Hidden } from 'react-grid-system'
import Button from '../../Button/Button'

const Intro = (props) => {
    return (
        <div className="card--simple">
            <div className="onboarding__content">
                <h1>
                    <p>
                        <Translate value="onboarding.titleStep1" />
                    </p>
                </h1>

                <Hidden xs sm>
                    <p>
                        <Translate value="onboarding.textStep1" />
                    </p>
                </Hidden>

                <img className={'card--simple__image img-smiley-edit'} alt="smiley" />
            </div>

            <div className="card--simple__buttons">
                <div>
                    <Button onClick={props.nextStep} className="button--cta inline-block">
                        <Translate value="buttons.getStarted" />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Intro
