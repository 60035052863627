export const TOGGLE_COLOR = 'TOGGLE_COLOR'
export const EXAMPLE_REQUEST_START = 'EXAMPLE_REQUEST_START'
export const EXAMPLE_REQUEST_DATA = 'EXAMPLE_REQUEST_DATA'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const UPDATE_USER_DATA = 'UPDATE_USER'

export const SUGGESTION_REQUEST = 'SUGGESTION_REQUEST'
export const SUGGESTION_SUCCESS = 'SUGGESTION_SUCCESS'
export const SUGGESTION_FAILURE = 'SUGGESTION_FAILURE'
export const SUGGESTION_NEXT = 'SUGGESTION_NEXT'

export const PROFILE_REQUEST = 'PROFILE_REQUEST'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_FAILURE = 'PROFILE_FAILURE'

export const PROFILE_DELETED_ITEM = 'PROFILE_DELETING'

export const PROFILE_ADDING_ITEM = 'PROFILE_ADDING'
export const PROFILE_ADDED_ITEM = 'PROFILE_ADDED'
export const PROFILE_ADD_ERROR = 'PROFILE_ADD_ERROR'

export const PROFILE_EDITING_ITEM = 'PROFILE_EDITING'
export const PROFILE_EDITED_ITEM = 'PROFILE_EDITED'
export const PROFILE_EDIT_ERROR = 'PROFILE_EDIT_ERROR'

export const CLEAR_PROFILE = 'CLEAR_PROFILE'

export const TOKEN_RENEWING = 'TOKEN_RENEWING'
export const TOKEN_RENEWED = 'TOKEN_RENEWED'
export const TOKEN_RENEW_ERROR = 'TOKEN_ERROR'
export const TOKEN_USED = 'TOKEN_USED'

export const CHECK_GO_BACK_TO = 'CHECK_GO_BACK_TO'
export const SET_GO_BACK_TO = 'SET_GO_BACK_TO'

export const WORKING_AREAS = 'WORKING_AREAS'
export const STUDIES = 'STUDIES'
export const ORGANIZATIONS = 'ORGANIZATIONS'
export const FLEXAPP_ORGANIZATIONS = 'FLEXAPP_ORGANIZATIONS'
export const FLEXAPP_ORGANIZATIONS_PUBLIC = 'FLEXAPP_ORGANIZATIONS_PUBLIC'
export const FILE_UPLOAD_TYPES = 'FILE_UPLOAD_TYPES'
export const ACCOUNT_FIELDS = 'ACCOUNT_FIELDS'
export const SET_EVENTS_REGISTERED_FOR = 'EVENTS_REGISTERED_FOR'
export const SET_EVENTS_OF_INTEREST = 'SET_EVENTS_OF_INTEREST'
export const SIGN_UP_FOR_EVENT = 'SIGN_UP_FOR_EVENT'
export const SIGN_OUT_FROM_EVENT = 'SIGN_OUT_FROM_EVENT'
export const SET_POSSIBLE_EVENT_INTERESTS = 'SET_POSSIBLE_EVENT_INTERESTS'
export const DIPLOMAS = 'DIPLOMAS'
export const DOCUMENTS_UPLOADED = 'DOCUMENTS_UPLOADED'
export const DOCUMENTS = 'DOCUMENTS'
export const VACANCY_REACTIONS = 'REACTIONS'
export const ADDED_DOCUMENT = 'ADDED_DOCUMENT'
export const VACANCY_REACTION = 'REACTION_ADDED'
export const VACANCY_REACTION_UPDATED = 'REACTION_UPDATED'
export const SET_PROFILE_PICTURE = 'profile_picture'
export const STUDY_LEVELS = 'study_levels'
export const SCHOOLS = 'schools'
export const SCHOOLS_REQUEST = 'schools_request'

export const ID_TOKEN = 'id_token'
export const PATH_LOGIN = 'login'
export const PATH_FORGOT_PASSWORD = 'wachtwoord-vergeten'
export const PATH_RESET_PASSWORD = 'herstel-wachtwoord'
export const PATH_STYLEGUIDE = 'styleguide'
export const PATH_ECOSYSTEM = 'ecosysteem'
export const PATH_SUGGESTIONS = 'suggesties'
export const PATH_APPLICATIONS = 'sollicitaties'
export const PATH_PROFILE = 'profiel'
export const PATH_SECURITY = 'veiligheid'
export const PATH_PROFILE_PRIVACY = 'privacy'
export const PATH_SHARING = 'delen'
export const PATH_PUBLIC_PROFILE = 'profiel/:username'
export const PATH_SETTINGS = 'instellingen'
export const PATH_REGISTER = 'registreren'
export const PATH_PRIVACY = 'gebruiksvoorwaarden'
export const PATH_ABOUT = 'over'
export const PATH_ACTIVATE = 'activeren'
export const PATH_VACANCIES = 'vacatures'
export const PATH_ROADMAP = 'roadmap'
export const PATH_TALENTPOOL = 'talentpool'

export const FIELD_PLACEHOLDER = '{field}'

export const FIRA_TYPEFACE = 'Fira Sans'

export const PROFILE_UPDATE_ITEM = 'update profile item'
export const PROFILE_UPDATED_ITEM = 'done updating profile item'
export const PROFILE_UPDATE_ERROR = 'error in profile update'

export const CONFIG_REQUESTED = 'configRequested'
export const CONFIG_RECEIVED = 'configReceived'
export const CONFIG_ERROR = 'configError'
export const CONFIG_SUBLABEL = 'configSublabel'
export const CONFIG_SUBLABEL_RESTORE = 'configSublabelRestore'

export const FILLED_IN_FLEXAPP_DATA = 'FILLED_IN_FLEXAPP_DATA'
export const SHOW_FLEXAPP_NOTIFICATION = 'SHOW_FLEXAPP_NOTIFICATION'
export const HIDE_FLEXAPP_NOTIFICATION = 'HIDE_FLEXAPP_NOTIFICATION'

export const TESTABLE_TYPE_EELLOO = 'App\\Models\\EellooTest'

export const PAGINATE_ITEM_NO = 5

export const MAX_SIZE_PROFILE_PICTURE = 5 * 1024 * 1024

export const ACCOUNT_ROLES = {
    PLANNER: 'planner',
    PLANNER_ADMINISTRATOR: 'planner-administrator',
    EMPLOYEE: 'employee',
    FLEXAPP_EMPLOYEE: 'flexapp-employee',
    CORONA: 'corona',
}

export const CREATION_OPTIONS = {
    ALREADY_TEACHING_VOTA: 'already_teaching_vota',
    ALREADY_TEACHING_NOT_VOTA: 'already_teaching_not_vota',
    STUDYING: 'studying',
    EXPLORING: 'exploring',
}

const { STUDYING, EXPLORING } = CREATION_OPTIONS
export const LATERAL_CREATION_OPTIONS = [STUDYING, EXPLORING]

const { PLANNER, PLANNER_ADMINISTRATOR, EMPLOYEE, FLEXAPP_EMPLOYEE, CORONA } = ACCOUNT_ROLES

export const FLEXAPP_ROLES = [PLANNER, PLANNER_ADMINISTRATOR, EMPLOYEE]
export const FLEXAPP_TYPES = [FLEXAPP_EMPLOYEE, CORONA, PLANNER, PLANNER_ADMINISTRATOR]
