import React, { Component } from 'react'
import { translate, Translate } from 'react-i18nify'
import { Hidden } from 'react-grid-system'
import Button from '../../Button/Button'

export default class Outro extends Component {
    getTextStep() {
        const { isFlexEmployee, isPlanner, isLateral } = this.props
        if (isLateral) {
            return translate('onboarding.textStepOutroLateral')
        }
        if (isPlanner) {
            return null
        }
        if (isFlexEmployee) {
            return translate('onboarding.textStepOutroFlex')
        }
        return translate('onboarding.textStep9')
    }
    getNextButton() {
        const { isFlexEmployee, isPlanner } = this.props

        if (isFlexEmployee || isPlanner) {
            return (
                <a
                    className="button--cta inline-block"
                    rel="noreferrer"
                    onClick={this.props.nextStep}
                    target="_blank"
                    href={this.props.flexUrl}
                >
                    <Translate value="onboarding.flexterm" />
                </a>
            )
        }
        return (
            <Button
                onClick={this.props.nextStep}
                className="button--cta inline-block"
                buttonText={translate('buttons.view_vacancies')}
            />
        )
    }
    render() {
        const { isPlanner } = this.props
        return (
            <div className="card--simple">
                <div className="onboarding__content">
                    <h1 className={'mixed-case'}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: isPlanner
                                    ? translate('onboarding.textStepOutroPlanner')
                                    : translate('onboarding.titleStep9'),
                            }}
                        />
                    </h1>

                    <p dangerouslySetInnerHTML={{ __html: this.getTextStep() }} />

                    <img className={'card--simple__image img-smiley-edit'} alt="smiley" />
                </div>

                <div className="card--simple__buttons">
                    <div>{this.getNextButton()}</div>

                    {this.props.onboardingStep !== 0 && (
                        <Hidden xs>
                            <button
                                onClick={this.props.prevStep}
                                className="button--link inline-block"
                            >
                                <i className="mdi mdi-arrow-left" />
                                <Translate value="onboarding.button_back" />
                            </button>
                        </Hidden>
                    )}
                </div>
            </div>
        )
    }
}
