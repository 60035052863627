import React from 'react'
import { Route, Switch } from 'react-router'

import {
    PATH_ABOUT,
    PATH_APPLICATIONS,
    PATH_FORGOT_PASSWORD,
    PATH_LOGIN,
    PATH_PRIVACY,
    PATH_PROFILE,
    PATH_PROFILE_PRIVACY,
    PATH_PUBLIC_PROFILE,
    PATH_ROADMAP,
    PATH_SECURITY,
    PATH_SHARING,
    PATH_STYLEGUIDE,
    PATH_SUGGESTIONS,
    PATH_TALENTPOOL,
    PATH_VACANCIES,
} from './constants/Constants'

// Pages
import About from './pages/About'
import PrivacyAndTerms from './pages/PrivacyAndTerms'
import Styleguide from './pages/Styleguide'
import Login from './pages/Login'
import PasswordForgotten from './pages/PasswordForgotten'
import PasswordReset from './pages/PasswordReset'
import Register from './pages/Register'
import PublicProfile from './pages/PublicProfile'
import Profile from './pages/Profile'
import Sharing from './pages/Sharing'
import Page404 from './pages/404'
import Activate from './pages/Activate'
import CardsPage from './pages/Cards'
import ApplyJobOffer from './pages/ApplyJobOffer'
import MyVacancies from './pages/MyVacancies'
import VacaturePage from './pages/VacaturePage'
import Security from './pages/Security'
import Roadmap from './pages/Roadmap'
import Vacancies from './pages/Vacancies'
import Privacy from './pages/Privacy'
import Talentpool from './pages/Talentpool'

export default (
    <Switch>
        <Route exact path={'/' + PATH_FORGOT_PASSWORD} component={PasswordForgotten} />

        <Route path={'/' + PATH_SUGGESTIONS} component={CardsPage} />

        <Route path={'/' + PATH_ABOUT} component={About} />

        <Route path={'/' + PATH_PRIVACY} component={PrivacyAndTerms} />

        <Route path={'/' + PATH_STYLEGUIDE} component={Styleguide} />

        <Route path="/apply/:id" component={ApplyJobOffer} />

        <Route path={'/' + 'herstel-wachtwoord/:token'} component={PasswordReset} />

        <Route exact path={'/' + 'registreren'} component={Register} />

        <Route path={'/' + 'registreren/:account_type_code'} component={Register} />

        <Route path={'/' + 'registreren/doelgroep/:target_group_id'} component={Register} />

        <Route path={'/' + 'activeren/:token/:reset/:cvsRedirect'} component={Activate} />

        <Route path={'/' + 'activeren/:token/:reset'} component={Activate} />

        <Route path={'/' + 'activeren/:token'} component={Activate} />

        <Route path={'/' + PATH_APPLICATIONS} component={MyVacancies} />

        <Route path={'/' + PATH_PROFILE} component={Profile} />

        <Route path={'/' + PATH_SECURITY} component={Security} />

        <Route path={'/' + PATH_PROFILE_PRIVACY} component={Privacy} />

        <Route path={'/' + PATH_PUBLIC_PROFILE} component={PublicProfile} />

        <Route path={'/' + PATH_SHARING} component={Sharing} />

        <Route path={'/' + 'vacature/:id'} component={VacaturePage} />

        <Route path={'/' + PATH_ROADMAP} components={Roadmap} />

        <Route path={'/' + PATH_VACANCIES} component={Vacancies} />

        <Route path={'/' + PATH_LOGIN + '/:ssoToken'} component={Login} />

        <Route path={'/' + PATH_TALENTPOOL} component={Talentpool} />

        <Route strict path="/" component={Login} />

        <Route path="*" component={Page404} />
    </Switch>
)
