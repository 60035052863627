import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Tag from '../Tag/Tag'

export default class MultiSelect extends Component {
    constructor(props) {
        super(props)

        this.onAddItem = this.onAddItem.bind(this)
    }

    onAddItem(selectedOption) {
        if (!this.props.value.includes(selectedOption)) {
            this.props.onChange([...this.props.value, selectedOption])
        }
    }

    render() {
        return (
            <div className={'form__item' + (this.props.requiredClass || '')}>
                <Select
                    className={'select--alt-black ' + this.props.classNames}
                    classNamePrefix="select"
                    id={this.props.id}
                    onChange={this.onAddItem}
                    value={{ value: 0, label: this.props.placeholder }}
                    clearable={false}
                    options={this.props.options}
                    isSearchable={true}
                />
                {this.props.label && (
                    <label className="form__label" htmlFor={this.props.id}>
                        <span>{this.props.label}</span>
                    </label>
                )}
                <div className="tag_container">
                    {this.props.value.map((item) => (
                        <Tag
                            key={item.value}
                            doNotConfirm
                            onRemove={() => {
                                this.props.onChange(
                                    this.props.value.filter((i) => i.value !== item.value),
                                )
                            }}
                        >
                            {item.label}
                        </Tag>
                    ))}
                </div>
            </div>
        )
    }
}

MultiSelect.propTypes = {
    value: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    id: PropTypes.string.isRequired,
}
