import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'
import history from '../../history'
import { logoutUser } from '../../store/actions/auth'
import {
    FLEXAPP_TYPES,
    PATH_APPLICATIONS,
    PATH_PROFILE,
    PATH_PROFILE_PRIVACY,
    PATH_SECURITY,
    PATH_SHARING,
    PATH_SUGGESTIONS,
    PATH_TALENTPOOL,
    PATH_VACANCIES,
} from '../../constants/Constants'

import { connect } from 'react-redux'
import { hideFlexappNotification, showFlexappNotification } from '../../store/actions/user'
import { redirectToFlexapp } from '../../helpers'

class NavigationDrawer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isStudent: false,
            isFlexapp: false,
        }
    }

    componentDidMount() {
        try {
            this.setState({
                isStudent:
                    this.props.user.profile.account_types &&
                    this.props.user.profile.account_types.some((type) => type.code == 'student'),
                isFlexapp:
                    this.props.user.profile.account_types &&
                    this.props.user.profile.account_types.some(({ code }) =>
                        FLEXAPP_TYPES.includes(code),
                    ),
            })
        } catch (e) {}
    }

    componentDidUpdate(prevProps) {
        try {
            if (
                this.props.user.profile.account_types &&
                prevProps.user.profile.account_types &&
                this.props.user.profile.account_types.length !==
                    prevProps.user.profile.account_types.length
            ) {
                this.setState({
                    isStudent: this.props.user.profile.account_types.some(
                        (type) => type.code == 'student',
                    ),
                    isFlexapp: this.props.user.profile.account_types.some(({ code }) =>
                        FLEXAPP_TYPES.includes(code),
                    ),
                })
            }
        } catch (e) {}
    }

    signOut() {
        this.props.hide()
        this.props.dispatch(logoutUser(this.props.auth, null, () => {}))
    }

    navigate() {
        this.props.hide()
    }

    redirectToFlexapp(e) {
        if (!this.props.user.profile.flex_organisations.some((o) => o.status === 1)) {
            e.preventDefault()
            this.navigate()
            this.props.dispatch(showFlexappNotification())
            setTimeout(() => {
                this.props.dispatch(hideFlexappNotification())
            }, 10000)
        } else {
            this.props.dispatch(hideFlexappNotification())
        }
    }

    render() {
        return (
            <div className="navigation_drawer">
                <div className="navigation">
                    <div className="main">
                        <Link onClick={() => this.navigate()} to={'/' + PATH_APPLICATIONS}>
                            <i className="icons icon-briefcase" />
                            <Translate value="menu.applications" />
                        </Link>
                    </div>
                    <div className="main">
                        <Link onClick={() => this.navigate()} to={'/' + PATH_VACANCIES}>
                            <i className="icons icon-location-pin" />
                            <Translate value="menu.vacancies" />
                        </Link>
                    </div>
                    {this.props.user.profile.talentpool_invitation_sent != 0 && (
                        <div className="main">
                            <Link onClick={() => this.navigate()} to={'/' + PATH_TALENTPOOL}>
                                <i className="icons icon-people" />
                                <Translate value="menu.talentpool" />
                            </Link>
                        </div>
                    )}
                    <div className="main">
                        <Link onClick={() => this.navigate()} to={'/' + PATH_PROFILE}>
                            <i className="icons icon-user" />
                            <Translate value="menu.profile" />
                        </Link>
                        <div className="sub">
                            <Link onClick={() => this.navigate()} to={'/' + PATH_SHARING}>
                                <i className="icons icon-share" />
                                <Translate value="menu.sharing" />
                            </Link>
                        </div>
                        <div className="sub">
                            <Link onClick={() => this.navigate()} to={'/' + PATH_SECURITY}>
                                <i className="icons icon-lock" />
                                <Translate value="menu.security" />
                            </Link>
                        </div>
                        <div className="sub">
                            <Link onClick={() => this.navigate()} to={'/' + PATH_PROFILE_PRIVACY}>
                                <i className="icons icon-shield" />
                                <Translate value="menu.privacy" />
                            </Link>
                        </div>
                    </div>
                    {this.state.isFlexapp && (
                        <>
                            <hr />
                            <div className="main flexapp__logo">
                                <a
                                    onClick={(e) =>
                                        redirectToFlexapp(
                                            e,
                                            this.props.user.profile,
                                            this.props.config,
                                            this.props.dispatch,
                                            this.props.auth,
                                        )
                                    }
                                    target={'_blank'}
                                    href={this.props.config.whitelabel.flexapp.url}
                                    rel="noreferrer"
                                >
                                    <img
                                        src={
                                            this.props.config.whitelabel.flexapp.url +
                                            `img${this.props.config.whitelabel.name}/flexapp-logo-shadow.png`
                                        }
                                    />
                                    <span>{this.props.config.whitelabel.flexapp.name}</span>
                                </a>
                            </div>
                        </>
                    )}
                    <hr />

                    <div className="main logout-button-wrapper">
                        <div
                            className="logout-button"
                            onClick={() => {
                                this.signOut()
                            }}
                        >
                            <i className="icons icon-logout" />
                            <span>
                                <Translate value="menu.logout" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

NavigationDrawer.propTypes = {
    hide: PropTypes.func,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(NavigationDrawer)
