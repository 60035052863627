import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Translate } from 'react-i18nify'
import Actions from '../Actions/Actions'

class OrganisationsGenerator extends Component {
    constructor(props) {
        super(props)

        let initialChecked = this.props.selectedOrganisations.map((org) => Number(org.id))
        this.state = {
            all: [],
            checked: initialChecked,
            filtered: [],
            filterValue: '',
            userOrganisationId: this.props.userOrganisationId,
        }

        this.isChecked = this.isChecked.bind(this)
        this.onCheckboxChange = this.onCheckboxChange.bind(this)
        this.filter = this.filter.bind(this)
        this.organisationToggle = this.organisationToggle.bind(this)
    }

    componentWillMount() {
        this.setState({ filtered: this.props.organisations, all: this.props.organisations })
    }

    isChecked(item) {
        let checked = this.state.checked

        return checked.indexOf(item.id) > -1
    }

    onCheckboxChange(e, item) {
        if (e.target.checked) {
            this.check(item)
        } else {
            this.uncheck(item)
        }
    }

    check(item) {
        if (!this.isChecked(item)) {
            let checked = this.state.checked
            let organisations = this.props.organisations

            checked.push(item.id)

            let manual = this.state.manual
            if (!this.contains(organisations, item) && !this.contains(manual, item)) {
                manual.push(item)
            }

            this.setState({
                checked: checked,
                manual: manual,
            })
        }
    }

    uncheck(item) {
        if (this.isChecked(item)) {
            let checked = this.state.checked

            //Remove id of the item from the checked list.
            checked.splice(checked.indexOf(item.id), 1)

            this.setState({
                checked: checked,
            })
        }
    }

    contains(list, item) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === item.id) {
                return true
            }
        }

        return false
    }

    filter(e) {
        let value = e.target.value.toLowerCase()

        this.setState({
            filtered: this.state.all.filter((el) => {
                return el.name.toLowerCase().includes(value)
            }),
            filterValue: e.target.value,
        })
    }

    organisationToggle(e) {
        if (e.target.checked) {
            this.checkAllOrganisations()
        } else {
            this.uncheckAllOrganisations()
        }
    }

    checkAllOrganisations() {
        this.setState({ checked: [] })

        let userOrganisationId = this.state.userOrganisationId
        let all = this.state.all
        let checked = this.state.checked

        all.forEach((organisation) => {
            if (organisation.id != userOrganisationId) {
                checked.push(organisation.id)
            }
        })

        this.setState({
            checked: checked,
        })
    }

    uncheckAllOrganisations() {
        this.setState({ checked: [] })
    }

    save() {
        let selected = this.props.organisations.filter(({ id }) => this.state.checked.includes(id))
        let preference = 'custom'

        if (selected.length == this.props.organisations.length) {
            preference = 'all'
        } else if (
            selected.length == this.props.organisations.length - 1 &&
            !selected.includes(this.state.userOrganisationId)
        ) {
            preference = 'all_except_current'
        } else if (selected.length == 0) {
            preference = 'none'
        }
        this.props.onSave(selected, preference)
    }

    getClassNames(item) {
        let names = 'form__item'

        if (this.isChecked(item)) {
            names += ' form__item--selected'
        }

        return names
    }

    render() {
        return (
            <form
                className="form--small_margin"
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        this.save()
                    }
                }}
            >
                <div className="form__header">
                    <label className="switch">
                        <input type="checkbox" onChange={(e) => this.organisationToggle(e)} />
                        <span className="slider round"></span>
                    </label>
                    <span className="switch-text">
                        <Translate value="talentpool.share_all" />
                    </span>
                </div>
                <fieldset className="form__fieldset">
                    <hr />
                    <input
                        className="form__input--text"
                        type="text"
                        placeholder="Filter rollen..."
                        onChange={(e) => this.filter(e)}
                        value={this.state.filterValue}
                    />
                    <div className="form__items">
                        {this.state.filtered.map((item, index) => {
                            return (
                                <div
                                    className={this.getClassNames(item)}
                                    key={'competence-' + index}
                                >
                                    <div className="form__checkbox">
                                        <input
                                            type="checkbox"
                                            name={item.id}
                                            id={item.id}
                                            className="form__checkbox__input"
                                            checked={this.isChecked(item)}
                                            onChange={(e) => this.onCheckboxChange(e, item)}
                                        />
                                        <label className="form__checkbox__label" htmlFor={item.id}>
                                            {item.name}
                                        </label>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            )
                        })}
                        {this.state.filtered.length === 0 && (
                            <div className="actions text-center">
                                <div>Geen resultaten</div>
                            </div>
                        )}
                    </div>
                </fieldset>
                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.cancel()}
                        tabIndex="5"
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link
                        className="actions__action--save"
                        onClick={() => this.save()}
                        tabIndex="6"
                    >
                        <Translate value="profile.save" />
                    </Link>
                </Actions>
            </form>
        )
    }
}

export default OrganisationsGenerator
