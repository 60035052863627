import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { translate, Translate } from 'react-i18nify'
import { Col, Hidden, Row } from 'react-grid-system'
import Button from '../../Button/Button'
import MultiSelect from '../../MultiSelect/MultiSelect'
import Select from 'react-select'
import { diffProfileItems, editProfileItem } from '../../../store/actions/user'
import AlertBox from '../../Alert/AlertBox'
import { connect } from 'react-redux'
import { checkForEmptyFields, getErrorFields, getErrorMessages, isRequired } from '../../../helpers'

class OnboardingAlt1Step5 extends Component {
    constructor(props) {
        super(props)
        const org = this.props.user.organizations.find(
            (item) => item.id === this.props.user.profile.organisation_id,
        )

        const orgs = this.props.user.profile.flex_organisations.map((o) => {
            let found = this.props.user.organizations.find((item) => item.id === o.organisation_id)
            return { value: o.organisation_id, label: found.name }
        })

        this.state = {
            loading: false,
            error: '',
            errors: [],
            fields: [],

            organisation: org ? { value: org.id, label: org.name } : null,
            organisations: orgs,
            options: [],
            flex_options: [],

            requiredFields: ['organisation'],
        }

        this.save = this.save.bind(this)
    }

    componentDidMount() {
        let requiredFields = this.state.requiredFields

        this.props.user.profile.account_fields.map((f) => {
            if (this.props.config.whitelabel.form_profile_extra_info.includes(f.code)) {
                this.setState({
                    [f.code]: f.value,
                })
            }
        })
        this.setState({
            options: this.props.user.organizations.map((item) => ({
                value: item.id,
                label: item.name,
            })),
            flex_options: this.props.user.flexapp_organizations
                .filter((item) => item.id)
                .map((item) => ({
                    value: item.id,
                    label: item.name,
                })),
            requiredFields: requiredFields,
        })
    }

    save() {
        if (this.state.loading) {
            return
        }
        let empty = checkForEmptyFields(this.state)
        if (empty.length > 0) {
            this.addErrors([translate('errors.empty_field')])
            this.addErrorFields(empty)
            return
        }

        this.setState({ loading: true, errors: [] })

        this.props.dispatch(
            editProfileItem(
                'general',
                { organisation_id: this.state.organisation.value },
                this.props.auth,
                () => {
                    this.saveFlexappOrganisations()
                },
                () => {},
            ),
        )
    }

    saveFlexappOrganisations() {
        let toAdd = []
        let toRemove = []

        let ids = this.state.organisations.map((org) => org.value)

        this.props.user.profile.flex_organisations.map((org) => {
            if (!ids.some((id) => id === org.organisation_id)) {
                toRemove.push(org.id)
            }
        })
        ids.forEach((id) => {
            if (
                !this.props.user.profile.flex_organisations.some(
                    (org) => id === org.organisation_id,
                )
            ) {
                toAdd.push({ organisation_id: id })
            }
        })

        this.props.dispatch(
            diffProfileItems('flex_organisations', toAdd, toRemove, this.props.auth, () => {
                if (this.props.config.whitelabel.form_profile_extra_info.includes('hours_from')) {
                    this.saveHours(ids)
                } else {
                    this.onSuccess(ids)
                }
            }),
        )
    }

    saveHours(ids) {
        let fields = this.props.config.whitelabel.form_profile_extra_info
        let fields_update = []
        for (let field of fields) {
            fields_update.push({
                code: field,
                value: this.state[field],
            })
        }
        this.props.dispatch(
            editProfileItem(
                'fields',
                {
                    account_fields: fields_update,
                },
                this.props.auth,
                () => {
                    this.onSuccess(ids)
                },
                (status, error) => {},
            ),
        )
    }

    onSuccess(ids) {
        let item = {
            organisation_id: this.state.organisation.value,
            flex_organisations: ids,
        }
        this.props.saveCache('flexAppStep', item)
        this.props.nextStep(item)
    }

    onError(status, object) {
        let map = {
            organisation: translate('labels.organisation'),
        }

        this.setState(
            {
                errors: [],
                fields: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    let fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    addErrors(errors) {
        this.setState({
            errors: errors,
        })
    }

    addErrorFields(fields) {
        this.setState({
            fields: fields,
        })
    }

    getClassNames(item, field) {
        let c = ''

        if (item != null && item != '' && ('' + item).length > 0) {
            c += ' has-value'
        }

        if (this.state.fields.indexOf(field) != -1) {
            c += ' error'
        }

        return c
    }

    render() {
        return (
            <div className="card--simple">
                <h1 className={'mixed-case'}>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: translate('onboarding.alt1.step5.title'),
                        }}
                    />
                </h1>

                <div className="onboarding__content">
                    <div className="form">
                        <div className={'form__item' + isRequired('organisation', this.state)}>
                            <Select
                                className={
                                    'select--alt-black' +
                                    this.getClassNames(this.state.organisation, 'organisation')
                                }
                                classNamePrefix="select"
                                id="current_employer"
                                onChange={(organisation) => this.setState({ organisation })}
                                value={this.state.organisation}
                                clearable={false}
                                options={this.state.options}
                                placeholder={translate(
                                    'onboarding.alt1.step5.select_current_employer',
                                )}
                                isSearchable
                            />
                            <label className="form__label" htmlFor={this.props.id}>
                                <Translate value="onboarding.alt1.step5.current_employer" />
                            </label>
                        </div>

                        <MultiSelect
                            value={this.state.organisations || []}
                            id="desired_employers"
                            onChange={(value) => {
                                this.setState({ organisations: value })
                            }}
                            options={this.state.flex_options}
                            placeholder={translate('onboarding.alt1.step5.select_employer')}
                            label={translate('onboarding.alt1.step5.desired_employer')}
                        ></MultiSelect>
                    </div>
                </div>

                {this.state.errors.length > 0 && (
                    <AlertBox messages={this.state.errors} type={'error'} />
                )}

                <div className="card--simple__buttons">
                    <div>
                        <Button
                            className={
                                'button--cta inline-block' +
                                (this.state.loading ? ' is-loading' : '')
                            }
                            buttonText={translate('buttons.continue')}
                            onClick={this.save}
                        />
                    </div>
                    <Hidden xs>
                        <button onClick={this.props.prevStep} className="inline-block button--link">
                            <i className="mdi mdi-arrow-left" />
                            <Translate value="onboarding.button_back" />
                        </button>
                    </Hidden>
                </div>
            </div>
        )
    }
}

OnboardingAlt1Step5.propTypes = {
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(OnboardingAlt1Step5)
